<script setup lang="ts">
import { ListingFilter } from '@shopware-pwa/types';
import { inject, ref } from 'vue';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import { Icon } from '@upa/design-system';
import { Checkbox } from '@upa/design-system';

const props = defineProps<{
  filter: ListingFilter;
}>();

const emits = defineEmits<{
  (e: 'select-value', value: { code: string; value: unknown }): void;
}>();
const selectedOptionIds = inject<string[]>('selectedOptionIds');
const isFilterVisible = ref<boolean>(true);
const toggle = () => {
  isFilterVisible.value = !isFilterVisible.value;
};

let initialOptionsToShow = ref(5);
let showAllOptions = ref(false);

const toggleMoreOptions = (filter: any) => {
  showAllOptions.value = !showAllOptions.value;
  if (filter.options.length > initialOptionsToShow.value) {
    initialOptionsToShow.value = filter.options.length;
  } else {
    initialOptionsToShow.value = 5;
  }
};
</script>

<template>
  <div class="listing-filter-properties-filter-category">
    <h3 class="-my-3 flow-root">
      <button
        type="button"
        class="flex w-full items-center bg-white py-2 text-base text-gray-400 hover:text-gray-500"
        @click="toggle"
      >
        <span
          class="text-left listing-filter-properties-filter-category-filter--label"
          >{{ filter.label }}</span
        >
        <span class="ml-6 flex items-center">
          <Icon :name="!isFilterVisible ? 'arrow_down' : 'arrow_up'" />
        </span>
      </button>
    </h3>
    <transition name="fade" mode="out-in">
      <div v-show="isFilterVisible" id="filter-section-0" class="pt-6">
        <!-- <div class="space-y-4"> -->
        <div class="listing-filter-properties">
          <!-- v-for="option in filter.options || filter.entities" -->
          <div
            v-for="option in filter.options?.slice(0, initialOptionsToShow) ||
            filter.entities?.slice(0, initialOptionsToShow)"
            :key="`${option.id}-${selectedOptionIds?.includes(option.id)}`"
            class="flex items-center filter-option"
          >
          <Checkbox
              :id="`filter-mobile-${filter.code}-${option.id}`"
              :selected="selectedOptionIds?.includes(option.id)"
              :label="getTranslatedProperty(option, 'name')"
              :value="option.name"
              class="filter-checkbox"
              @change="
              emits('select-value', {
                code: filter.code,
                value: option.id,
              })
            "
            />
          </div>
          <!-- // btn show more here or deeper? -->
          <div
            v-if="filter.options && filter.options.length > 5"
            class="show-more-less-text"
          >
            <a
              href="#"
              class="product-filter-sidebar__link"
              @click.prevent="toggleMoreOptions(filter)"
            >
              <!-- {{ showAllOptions ? $t('ocProductFilterSidebar.showMore') : $t('ocProductFilterSidebar.showLess') }} -->
              <!-- TODO: snippets -->
              {{
                showAllOptions ? $t('listing.showLess') : $t('listing.showMore')
              }}
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.listing-filter-properties-filter-category-filter--label {
  color: #1d1f22;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  font-family: var(--font-family--medium);
}

.filter-option {
  color: #1d1f22;
  font-family: var(--font-family--primary);
  line-height: 22px; /* 137.5% */
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  overflow: hidden;
  margin-top: 4px;
}


.product-filter-sidebar__link {
  color: var(--_c-black-primary);
}

.listing-filter-properties-filter-category {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--_c-gray-light-accent);

  @media (min-width: 1024px) {
    margin-right: 24px;
  }
}

.show-more-less-text {
  margin-top: 12px;
}
</style>
